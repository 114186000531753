<template>
  <div class="harvest-box">
    <h3>Teambition可以带来什么</h3>
    <p>不仅全球1000万用户都喜欢，从初创团队到行业巨头，还受到优秀企业青睐。</p>
    <ul>
      <li>
        <img src="/static/img/harvest-img1.jpg"/>
        <div>
          <h3>协作无间</h3>
          <p>不在一个办公室也能找准节拍，彼此之间充满默契，实时同步进展、共享资料或者协同编辑文档，你甚至意识不到远程协作正在发生，一切就已经完成了。</p>
        </div>
      </li>
      <li>
        <img src="/static/img/harvest-img2.jpg"/>
        <div>
          <h3>生产力<sup>n</sup></h3>
          <p>从低效会议和重复的事务性工作中解放出来，让视角和工作方式产生变化，享受专注，收获高效创造一切的喜悦。</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<style lang="less" scoped>
.harvest-box{
  padding: 50px 25px 60px;
  background-color: #070D38;
  color: #fff;
  >h3{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    // text-transform: uppercase;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 25px;
    margin-bottom: 40px;
    line-height: 2;
  }
  >ul{
    margin-top: 35px;
    >li{
      position: relative;
      &:not(:last-child){
        margin-bottom: 25px;
      }
      >img{
        width: 100%;
        display: block;
      }
      >div{
        position: absolute;
        top: 50%;
        left: 0;
        box-sizing: border-box;
        padding: 0 25px;
        transform: translateY(-50%);
        >h3{
          font-size: 20px;
          color: #F23D49;
          font-weight: bold;
          text-align: center;
        }
        >p{
          font-size: 15px;
          color: #fff;
          font-weight: 300;
          line-height: 1.8;
          margin-top: 20px;
          text-align: justify;
        }
      }
    }
  }
}
</style>