<template>
  <ul class="case-list">
    <li v-for="(item, index) in list" :key="index">
      <h3>{{item.title}}</h3>
      <img :src="item.img"/>
      <p>{{item.remark}}</p>
    </li>
  </ul>
</template>
<script>
export default {
  data() {
    return {
      list:[
        {img:'/static/img/tb-img1.jpg',title:'小米',remark:'小米让「万物互联」的本质是效率革命，IoT 平台部需要与数百家企业高效协作。'},
        {img:'/static/img/tb-img2.jpg',title:'得到',remark:'200 小时打造 30 分钟内容，1500 本「听书」都是这样诞生的。'},
        {img:'/static/img/tb-img3.jpg',title:'永辉云创',remark:'「新零售」企业需要这样打通前中后台，以统一数据驱动业务发展。'},
        {img:'/static/img/tb-img4.jpg',title:'上海交大安泰高管教育中心',remark:'亚洲排名第一的高管教育机构，使用 Teambition 探索全新协作和管理方式。'},
        {img:'/static/img/tb-img5.jpg',title:'喜马拉雅',remark:'服务 4.7 亿用户的大型产研团队，使用 Teambition 将迭代周期缩短至 1/3。'},
        {img:'/static/img/tb-img6.jpg',title:'凯叔讲故事',remark:'借助Teambition打造一支敏捷开发团队，高效响应2000万用户需求。'},
        {img:'/static/img/tb-img7.jpg',title:'智华汽车',remark:'这家崇尚工程师文化的高新技术企业，使用轻量级项目管理工具激发更多创新。'},
        {img:'/static/img/tb-img8.jpg',title:'商米',remark:'这家商用智能物联网领域的独角兽企业，是敏捷改造的成功典范。'},
        {img:'/static/img/tb-img9.jpg',title:'趣链科技',remark:'这家区块链准独角兽企业与 Teambition 一起探索，在 3 个月内完成敏捷转型。'},
        {img:'/static/img/tb-img10.jpg',title:'矩阵纵横',remark:'业务规范、项目过程和设计师资源，都可以在 Teambition 上透明化管理。'},
        {img:'/static/img/tb-img11.jpg',title:'标谱国际室内设计',remark:'Teambition是直观高效的信息化手段，让这家企业打破效率瓶颈。'},
        {img:'/static/img/tb-img12.jpg',title:'予味设计',remark:'使用 Teambition 管理好创作规范，才能持续创作出「不循规蹈矩」的好作品。'},
        {img:'/static/img/tb-img13.jpg',title:'少数派',remark:'专门研究「工具」的专业媒体，使用 Teambition 管理内容生产和公司运营。'},
        {img:'/static/img/tb-img14.jpg',title:'Teambition DAY',remark:'使用全新的方式筹备活动，让市场部告诉你，我们如何用Teambition 策划 Teambition Day。'},
        {img:'/static/img/tb-img15.jpg',title:'PP 酱',remark:'跨城市、跨部门也可以一起高效协作，这家设计公司说Teambition 是刚需。'},
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.case-list{
  display: flex;
  align-items:stretch;
  flex-wrap: wrap;
  >li{
    width: 50%;
    padding: 15px 15px 20px;
    color: #383838;
    position: relative;
    margin: initial;
    >img{
      width: 100%;
      display: block;
      margin: 10px 0;
      z-index: 2;
      position: relative;
    }
    >h3{
      font-size: 15px;
      font-weight: 300;
      text-align: center;
      // text-transform: uppercase;
      z-index: 2;
      position: relative;
    }
    >p{
      font-size: 13px;
      font-weight: 300;
      line-height: 1.5;
      z-index: 2;
      position: relative;
      text-align: justify;
    }
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      bottom: 3px;
      right: 3px;
      background: #FFFFFF;
      border: 1px solid #E8EAED;
    }
  }
}
</style>