<template>
  <div class="tb-solution">
    <h3>不同团队的解决方案 · SolutionS</h3>
    <ul>
      <li :class="{'on':type=='DEVELOPMENT'}" @click="type='DEVELOPMENT'">研发</li>
      <li :class="{'on':type=='PRODUCT'}" @click="type='PRODUCT'">产品</li>
      <li :class="{'on':type=='TESTING'}" @click="type='TESTING'">测试</li>
      <li :class="{'on':type=='DESIGN'}" @click="type='DESIGN'">设计</li>
      <li :class="{'on':type=='HR'}" @click="type='HR'">人事</li>
      <li :class="{'on':type=='MARKETING'}" @click="type='MARKETING'">市场运营</li>
    </ul>
    <div class="tb-solution-content">
      <transition name="slide-fade" mode="out-in">
        <div v-if="type=='DEVELOPMENT'" class="tb-info">
          <h3>研发<br/>development</h3>
          <img src="/static/img/tb-solution-img1.jpg"/>
          <p>敏捷团队喜爱的研发管理工具，全面支持「看板」和「SCRUM」敏捷方法，可围绕产品目标灵活规划每个迭代冲刺。实时数据反馈让计划调整更及时，团队成员积极应对变化，持续交付价值。</p>
        </div>
        <div v-else-if="type=='PRODUCT'" class="tb-info">
          <h3>产品<br/>PRODUCT</h3>
          <img src="/static/img/tb-solution-img2.jpg"/>
          <p>从需求收集、设计到开发，把需求优雅地落实到位。Teambition 支持复杂的需求分析和管理场景，让产品团队轻松应对多个需求来源或者多条业务线，推动产品快速响应市场变化。</p>
        </div>
        <div v-else-if="type=='TESTING'" class="tb-info">
          <h3>测试<br/>TESTING</h3>
          <img src="/static/img/tb-solution-img3.jpg"/>
          <p>使用 Teambition 快速搭建一体化的「开发 - 测试 - 反馈」流程，无需切换工具，测试和开发团队在一个工作台上实时协同项目进度，有效提升交付质量。</p>
        </div>
        <div v-else-if="type=='DESIGN'" class="tb-info">
          <h3>设计<br/>DESIGN</h3>
          <img src="/static/img/tb-solution-img4.jpg"/>
          <p>使用 Teambition 协同创作，排期紧、任务繁杂或者文件众多，团队都能轻松应对。全新的协作方式，让工作变得井然有序，有助于保障交付品质。</p>
        </div>
        <div v-else-if="type=='HR'" class="tb-info">
          <h3>人事<br/>HR</h3>
          <img src="/static/img/tb-solution-img5.jpg"/>
          <p>Teambition是一套自由度极高的工具，人事行政部门可以灵活搭建项目，轻松管理招聘、绩效统计等日常工作；Teambition 更是一套管理方法，帮助企业提升人效和工作环境。</p>
        </div>
        <div v-else-if="type=='MARKETING'" class="tb-info">
          <h3>市场运营<br/>MARKETING</h3>
          <img src="/static/img/tb-solution-img6.jpg"/>
          <p>营销越来越复杂，更需要科学的方法来简化工作。Teambition 提供崭新的项目化管理方式，无论是新品发布、线上营销还是创意活动，都可以高效执行到位，稳步实现增长目标。</p>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type:'DEVELOPMENT',
      // list:[
      //   {type:'DEVELOPMENT',name:'研发',img:'/static/img/tb-solution-img1.jpg',remark:'敏捷团队喜爱的研发管理工具，全面支持「看板」和「SCRUM」敏捷方法，可围绕产品目标灵活规划每个迭代冲刺。实时数据反馈让计划调整更及时，团队成员积极应对变化，持续交付价值。'},
      //   {type:'PRODUCT',name:'产品',img:'/static/img/tb-solution-img2.jpg',remark:'从需求收集、设计到开发，把需求优雅地落实到位。Teambition 支持复杂的需求分析和管理场景，让产品团队轻松应对多个需求来源或者多条业务线，推动产品快速响应市场变化。'},
      //   {type:'TESTING',name:'测试',img:'/static/img/tb-solution-img3.jpg',remark:'使用 Teambition 快速搭建一体化的「开发 - 测试 - 反馈」流程，无需切换工具，测试和开发团队在一个工作台上实时协同项目进度，有效提升交付质量。'},
      //   {type:'DESIGN',name:'设计',img:'/static/img/tb-solution-img4.jpg',remark:'使用 Teambition 协同创作，排期紧、任务繁杂或者文件众多，团队都能轻松应对。全新的协作方式，让工作变得井然有序，有助于保障交付品质。'},
      //   {type:'HR',name:'人事',img:'/static/img/tb-solution-img5.jpg',remark:'Teambition是一套自由度极高的工具，人事行政部门可以灵活搭建项目，轻松管理招聘、绩效统计等日常工作；Teambition 更是一套管理方法，帮助企业提升人效和工作环境。'},
      //   {type:'MARKETING',name:'市场运营',img:'/static/img/tb-solution-img6.jpg',remark:'营销越来越复杂，更需要科学的方法来简化工作。Teambition 提供崭新的项目化管理方式，无论是新品发布、线上营销还是创意活动，都可以高效执行到位，稳步实现增长目标。'},
      // ]
    }
  },
}
</script>
<style lang="less" scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity .3s
}

.slide-fade-enter-from, .slide-fade-leave-to {
  opacity: 0
}
.tb-solution{
  padding: 45px 0;
  >h3{
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  >ul{
    display: flex;
    align-items: center;
    margin-top: 30px;
    height: 40px;
    border-bottom: 1px solid #E8E8E8;
    >li{
      font-size: 15px;
      color: #383838;
      position: relative;
      line-height: 38px;
      &.on::after{
        content: '';
        position: absolute;
        left: -5px;
        right: -5px;
        bottom: 1px;
        height: 2px;
        background-color: #E80B10;
      }
    }
  }
  .tb-solution-content{
    min-height: 400px;
    .tb-info{
      padding: 30px 25px;
      color: #383838;
      >h3{
        font-size: 18px;
        line-height: 1.8;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
      }
      >img{
        width: 100%;
        display: block;
        margin-top: 25px;
      }
      >p{
        font-size: 15px;
        line-height: 2;
        font-weight: 300;
        margin-top: 25px;
        text-align: justify;
      }
    }
  }
}
</style>