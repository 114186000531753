<template>
  <div class="cases-box">
    <h3>客户案例  ·  CASES</h3>
    <CaseList/>
  </div>
</template>
<script>
import CaseList from './CaseList.vue';
export default {
  components:{
    CaseList
  }
}
</script>
<style lang="less" scoped>
.cases-box{
  padding: 50px 10px;
  background-color: #F6F6F8;
  >h3{
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }
}
</style>